import React, { Component } from "react";
import { connect } from 'react-redux';

import {
  TabContent, TabPane, Nav, NavItem, NavLink, Card, CardHeader, CardBody, Row,
  Col, CardTitle, Button, FormGroup, Label, Input
} from "reactstrap";
import styled from 'styled-components';

import Datetime from "react-datetime";
import moment from 'moment';
import { Line } from "react-chartjs-2";
import { participantsActions } from '../../actions';
import { questionsActions } from '../../actions';
import { dataTransService } from '../../services';
import DateFilter from "components/DateFilter/DateFilter.jsx";
import { PageWrapper } from 'components/PageWrapper/PageWrapper';
import { PatientBanner } from 'components/PatientBanner/PatientBanner';
import { AlertsList } from 'views/ServiceUsers/components/AlertsList';
import { QuestionnaireList } from 'views/ServiceUsers/components/QuestionnaireList';
import { EncountersOverview } from 'views/ServiceUsers/components/EncountersOverview';
import { SymptomsGraphsTab } from 'views/ServiceUsers/components/SymptomsGraphsTab';

const QuestionsList = styled.div`
  max-height: 450px;
  overflow-y: auto;
`

class ParticipantSummary extends Component {
  constructor(props) {
    super(props);
    this.handleStartDateConcept = this.handleStartDateConcept.bind(this);
    this.handleEndDateConcept = this.handleEndDateConcept.bind(this);
    this.handleStartDateQuestion = this.handleStartDateQuestion.bind(this);
    this.handleEndDateQuestion = this.handleEndDateQuestion.bind(this);
    this.dateStartConcept = Datetime.moment();
    this.dateEndConcept = Datetime.moment();
    this.dateStartQuestion = Datetime.moment();
    this.dateEndQuestion = Datetime.moment();
    var emptyChartData = {
      data: canvas => {
        return {
          labels: [],
          datasets: []
        }
      }
    };

    this.state = {
      pId: ' - loading data ... - ',
      hTabs: "ht1",
      startDate: Datetime.moment(), //JLIZASO2022
      endDate: Datetime.moment(),  //JLIZASO2022
      conceptsStartDate: Datetime.moment(),
      conceptsEndDate: Datetime.moment(),
      questionsStartDate: Datetime.moment(),
      questionsEndDate: Datetime.moment(),

      conceptsMinDate: Datetime.moment(),
      conceptsMaxDate: Datetime.moment(),
      questionsMinDate: Datetime.moment(),
      questionsMaxDate: Datetime.moment(),

      datasetGraphEncounters: null,
      alarmNumber: 0,

      minmaxDateFixed: false,

      concepts: [],
      questions: [],
      //questionsArray: [],
      arrayConceptsChartData: [], //JLIZASO2022
      arrayEncountersChartData: [],
      conceptsChartData: {
        data: emptyChartData
      },

      selectAllQ: 'Unselect All',
      selectAllC: 'Unselect All',

      selectAllQE: true,
      selectAllCE: true,

      questionsChartData: {
        data: emptyChartData
      },
    };

    this.props.getSummary(props.match.params.pid);
    //this.state.questionsArray = this.props.getQuestions(); //JLIZASO
    //console.log("JLIZASO this.state.questionsArray:", this.state.questionsArray);

    this.MinMaxAnswerValues = { //JLIZASO
      max: 10,
      min: 0
    }

    this.MinMaxSideEffectsValues = { //JLIZASO
      max: 3,
      min: 0
    }

    this.setChartOptions();
    this.setEncounterChartOptions();
    this.setSEChartOptions();

  }

  setChartOptions() {
    this.chartOptions = {
      animations: {
        radius: {
          duration: 400,
          easing: 'linear',
          loop: (context) => context.active
        }
      },
      spanGaps: true,
      layout: {
        padding: { left: 15, right: 15, top: 5, bottom: 100 }
      },
      maintainAspectRatio: false,
      responsive: 1,
      tooltips: {
        bodySpacing: 4,
        mode: "nearest",
        intersect: 0,
        position: "nearest",
        xPadding: 10,
        yPadding: 10,
        caretPadding: 10
      },
      legend: {
        display: false
      },
      /*tooltips: {
        /*callbacks: {
            label: function(tooltipItem) {
              console.log("tooltipItem", tooltipItem);
              return Number(tooltipItem.yLabel) + " Date";

              var date = value;
              if(moment(date).format('YYYY')===moment().format('YYYY')){
                date = moment(value).format('DD/MM');
              } else {
                date = moment(value).format('DD/MM/YY');
              }

              return date;
            }
        }
      },*/
      scales: {
        yAxes: [
          {
            gridLines: {
              display: true,
              zeroLineColor: "transparent",
              drawTicks: true,
              drawBorder: true,
            },
            ticks: {
              fontStyle: "bold",
              beginAtZero: true,
              padding: 5,
              suggestedMin: this.MinMaxAnswerValues.min, // Multitouch: The range is now variable. //JLIZASO
              suggestedMax: this.MinMaxAnswerValues.max, // DAWNP: The range of values is from 0 to 3
              min: this.MinMaxAnswerValues.min, // DAWNP: The range of values is from 0 to 3
              max: this.MinMaxAnswerValues.max, // DAWNP: The range of values is from 0 to 3
              stepSize: 1
            }
          }
        ],
        xAxes: [{
          type: 'time', //time linear
          distribution: 'linear',
          ticks: {
            source: 'labels',
            padding: 10,
            beginAtZero: false,
            fontStyle: "bold",
            //maxTicksLimit: 8.1,
            autoSkip: false,
            major: {
              enabled: true
            },
            /*callback: function(value) { // , index, values
              var date = value;
              if(moment(date).format('YYYY')===moment().format('YYYY')){
                date = moment(value).format('DD/MM');
              } else {
                date = moment(value).format('DD/MM/YY');
              }

              return date;
            }*/
          },
          time: {
            displayFormats: {
              day: 'DD/MM'
            },
            tooltipFormat: 'DD/MM/YY HH:MM',
            unit: 'day',
            unitStepSize: 1,
          },
          gridLines: {
            display: true,
            zeroLineColor: "transparent",
            drawTicks: true,
            drawBorder: true, //20220210
          },
        }]
      }
    };
  }

  /* This is a hardcoded solution to the issue, should make it dinamic later */
  setSEChartOptions() {
    this.seChartOptions = {
      animations: {
        radius: {
          duration: 400,
          easing: 'linear',
          loop: (context) => context.active
        }
      },
      spanGaps: true,
      layout: {
        padding: { left: 15, right: 15, top: 5, bottom: 100 }
      },
      maintainAspectRatio: false,
      responsive: 1,
      tooltips: {
        bodySpacing: 4,
        mode: "nearest",
        intersect: 0,
        position: "nearest",
        xPadding: 10,
        yPadding: 10,
        caretPadding: 10
      },
      legend: {
        display: false
      },
      scales: {
        yAxes: [
          {
            gridLines: {
              display: true,
              zeroLineColor: "transparent",
              drawTicks: true,
              drawBorder: true,
            },
            ticks: {
              fontStyle: "bold",
              beginAtZero: true,
              padding: 10,
              suggestedMin: this.MinMaxSideEffectsValues.min,
              suggestedMax: this.MinMaxSideEffectsValues.max,
              min: this.MinMaxSideEffectsValues.min,
              max: this.MinMaxSideEffectsValues.max,
              stepSize: 0.5
            }
          }
        ],
        xAxes: [{
          type: 'time', //time linear
          distribution: 'linear',
          ticks: {
            source: 'labels',
            padding: 10,
            beginAtZero: false,
            fontStyle: "bold",
            //maxTicksLimit: 8.1,
            autoSkip: false,
            major: {
              enabled: true
            },
          },
          time: {
            displayFormats: {
              day: 'DD/MM'
            },
            tooltipFormat: 'DD/MM/YY HH:MM',
            unit: 'day',
            unitStepSize: 1,
          },
          gridLines: {
            display: true,
            zeroLineColor: "transparent",
            drawTicks: true,
            drawBorder: true, //20220210
          },
        }]
      }
    };
  }


  setEncounterChartOptions() {
    this.encounterChartOptions = {
      layout: {
        padding: { left: 15, right: 15, top: 5, bottom: 100 }
      },
      plugins: {
        title: {
          display: false,
        },
      },
      tooltips: {
        callbacks: {
          label: function (tooltipItem) {
            //console.log("tooltipItem", tooltipItem);
            return Number(tooltipItem.yLabel) + "% of engagement";
          }
        }
      },
      responsive: true,
      scales: {
        xAxes: [{
          stacked: false,
        }],
        yAxes: [{
          stacked: false,
          ticks: {
            suggestedMin: 0,
            suggestedMax: 100, //this.state.alarmNumber + 1
            beginAtZero: true,
            grace: 1,
            stepSize: 10,
            callback: function (value, index, values) {
              return value + "%";
            }
          }
        }],
      },
    }
  }

  handleStartDateConcept(date) {
    //console.log("/////// handleStartDate date/conceptsMinDate: ", date.toString(), " - ", moment(date).isValid(), " - ", this.dateStartConcept.format('MM/DD/YYYY'), "/", this.dateEndConcept.format('MM/DD/YYYY'));
    if (!moment(date).isValid() || moment(date) < moment(this.state.conceptsMinDate).subtract(3650, "day")) {
      date = this.state.conceptsMinDate;
    }

    this.dateStartConcept = moment(date); // LASTFIX
    this.setState({ conceptsStartDate: moment(date) },
      this.plotContepts(undefined, moment(date), undefined));
  }

  handleEndDateConcept(date) {
    //console.log("/////// handleEndDate date/conceptsMinDate: ", date.toString(), " - ", moment(date).isValid(), " - ", this.dateStartConcept.format('MM/DD/YYYY'), "/", this.dateEndConcept.format('MM/DD/YYYY'));
    if (!moment(date).isValid() || moment(date) > moment(this.state.conceptsMaxDate).add(3650, "day")) {
      date = this.state.conceptsMaxDate;
    }

    this.dateEndConcept = moment(date); // LASTFIX
    this.setState({ conceptsEndDate: moment(date) },
      this.plotContepts(undefined, undefined, moment(date)));
  }


  handleStartDateQuestion(date) {
    if (!moment(date).isValid() || moment(date) < moment(this.state.questionsMinDate).subtract(3650, "day")) {
      date = this.state.questionsMinDate;
    }

    this.dateStartQuestion = moment(date); // LASTFIX
    this.setState({ questionsStartDate: moment(date) },
      this.plotQuestions(undefined, moment(date), undefined));
  }

  handleEndDateQuestion(date) {
    if (!moment(date).isValid() || moment(date) > moment(this.state.questionsMaxDate).add(3650, "day")) {
      date = this.state.questionsMaxDate;
    }

    this.dateEndQuestion = moment(date); // LASTFIX
    this.setState({ questionsEndDate: moment(date) },
      this.plotQuestions(undefined, undefined, moment(date)));
  }


  /*
    handleChangeStartSymptoms(date) {
      this.setState({ conceptsStartDate: date });
      this.plotContepts(undefined, date, undefined);
    }
  
    handleChangeEndSymptoms(date) {
      this.setState({ conceptsEndDate: date });
      this.plotContepts(undefined, undefined, date);
    }
  
    handleChangeStartQuestions(date) {
      this.setState({ questionsStartDate: date });
      this.plotQuestions(undefined, date, undefined);
    }
  
    handleChangeEndQuestions(date) {
      this.setState({ questionsEndDate: date });
      this.plotQuestions(undefined, undefined, date);
    }
  */

  plotQuestions(questionsArr, dstart, dend) {

    if (dstart === undefined) {
      //dstart = this.state.questionsStartDate;
      dstart = this.dateStartQuestion;
    }

    if (dend === undefined) {
      //dend = this.state.questionsEndDate;
      dend = this.dateEndQuestion;
    }

    if (questionsArr === undefined) {
      questionsArr = this.state.questions;
    }

    var selectedQuestions = [];

    for (let i = 0; i < questionsArr.length; i++) {
      const q = questionsArr[i];
      if (q.selected === true) {
        selectedQuestions.push(q);
      }
    }

    const datasets = dataTransService.getQuestionsPlotData(
      this.props.summary.encounters,
      selectedQuestions,
      {
        minDate: Datetime.moment(dstart),
        maxDate: Datetime.moment(dend),
      }
    );

    const labels = dataTransService.getXAxisTicks(
      Datetime.moment(dstart).valueOf(),
      Datetime.moment(dend).valueOf()
    ).map(date => date[0]);

    var newQuestionsChartData = {
      data: canvas => {
        return {
          labels: labels,
          datasets: datasets
        }
      }
    }
    this.setState({ questionsChartData: newQuestionsChartData });
  }

  canvas(labels, datasets) {
    return {
      labels: labels,
      datasets: datasets
    }
  }

  plotContepts(conceptsArr, dstart, dend) {
    //console.log("///////////////////plotContepts/////////////////////////////");
    //console.log("plotContepts: ", conceptsArr, ((dstart!==undefined)?dstart.toString():null), ((dend!==undefined)?dend.toString():null), " - ", this.dateStartConcept.format('MM/DD/YYYY'), "/", this.dateEndConcept.format('MM/DD/YYYY'));
    if (dstart === undefined) {
      //dstart = this.state.conceptsStartDate; LASTFIX
      dstart = this.dateStartConcept;
    }

    if (dend === undefined) {
      //dend = this.state.conceptsEndDate; LASTFIX
      dend = this.dateEndConcept;
    }

    if (conceptsArr === undefined) {
      conceptsArr = this.state.concepts;
    }
    //console.log("plotContepts: ", conceptsArr, dstart, dend, moment(dstart), moment(dend), this.props.summary.encounters);

    var selectedConcepts = [];
    var datasets = {};
    var tmpArrayConceptsChartData = [];
    var newConceptsChartData = {};
    var labels;

    /*
    const canvas = (labels, datasets) => {
      return {
        labels: labels,
        datasets: datasets
      }
    };

    for (let i = 0; i < conceptsArr.length; i++) {
      const q = conceptsArr[i];
      console.log("plotContepts conceptsArr concept: ", q);
    };*/

    /*
    var seArray = conceptsArr.filter(concept => concept.name === "Side Effects");
    var deArray = conceptsArr.filter(concept => concept.name === "Delusions");
    var ahArray = conceptsArr.filter(concept => concept.name === "Auditory Hallucinations");
    var vhArray = conceptsArr.filter(concept => concept.name === "Visual Hallucinations");

    var restArray = conceptsArr.filter(concept => concept.name !== "Visual Hallucinations" &&
                                                  concept.name !== "Side Effects" &&
                                                  concept.name !== "Delusions" &&
                                                  concept.name !== "Auditory Hallucinations"
                                      );

    conceptsArr = seArray.concat(deArray, ahArray, vhArray, restArray);*/

    for (let i = 0; i < conceptsArr.length; i++) {
      const q = conceptsArr[i];
      selectedConcepts = [];
      selectedConcepts.push(q);
      datasets = dataTransService.getConceptsPlotData(
        this.props.summary.encounters,
        selectedConcepts,
        {
          minDate: moment(dstart),
          maxDate: moment(dend),
        }
      );

      //console.log("plotContepts getXAxisTicks: ",  dstart, dend, moment(dstart), moment(dend));
      labels = dataTransService.getXAxisTicks(
        moment(dstart),
        moment(dend)
      ).map(date => date[0]);
      //console.log("plotContepts : labels", labels )

      //console.log("plotContepts", dstart, dend, Datetime.moment(dstart), Datetime.moment(dend), Datetime.moment(dstart).valueOf(), Datetime.moment(dend).valueOf())

      newConceptsChartData = {
        data: this.canvas(labels, datasets)
      }
      tmpArrayConceptsChartData.push({ concept: q.id, conceptsChartData: newConceptsChartData });
    };

    this.setState({ arrayConceptsChartData: tmpArrayConceptsChartData });
    //console.log("plotContepts tmpArrayConceptsChartData:", tmpArrayConceptsChartData, conceptsArr);

    //this.handleAllConcept();

    /* JLIZASO 2022 - Show All Concepts, but in diferent Graphs
    for (let i = 0; i < conceptsArr.length; i++) {
      const q = conceptsArr[i];
      if (q.selected === true) {
        selectedConcepts.push(q);
      }
    }

    var datasets = dataTransService.getConceptsPlotData(
      this.props.summary.encounters,
      selectedConcepts,
      {
        minDate: Datetime.moment(dstart),
        maxDate: Datetime.moment(dend),
      }
    );

    const labels = dataTransService.getXAxisTicks(
      Datetime.moment(dstart).valueOf(),
      Datetime.moment(dend).valueOf()
    ).map(date => date[0]);

    var newConceptsChartData = {
      data: canvas => {
        return {
          labels: labels,
          datasets: datasets
        }
      }
    }
    this.setState({ conceptsChartData: newConceptsChartData });*/
  }


  changeQuestionsSelection() {
    var selection = !this.state.selectAllQE;
    if (selection === false) {
      this.setState({ selectAllQ: 'Select All' });
    } else {
      this.setState({ selectAllQ: 'Unselect All' });
    }
    this.setState({ selectAllQE: selection });

    var newQuestions = this.state.questions.map(el => {
      el.selected = selection;
      return el;
    });
    this.setState({ questions: newQuestions },
      this.plotQuestions(newQuestions));
  }

  /* JLIZASO 2022 - Show All Concepts, but in diferent Graphs
  changeConceptsSelection() {
    console.log("HERE3");
    var selection = !this.state.selectAllCE;
    if (selection === false) {
      this.setState({ selectAllC: 'Select All' });
    } else {
      this.setState({ selectAllC: 'Unselect All' });
    }
    this.setState({ selectAllCE: selection });

    var newConcepts = this.state.concepts.map(el => {
      el.selected = selection;
      return el;
    });
    this.setState({ concepts: newConcepts })
    this.plotContepts(newConcepts);
  }*/

  toggleModalNotice = (el) => {

    el.modalNotice = !el.modalNotice;
    this.forceUpdate();
  };

  /*
  isValidStartDateForConcepts(current) {
    return current.isBefore(this.state.conceptsEndDate);
  }

  isValidEndDateForConcepts(current) {
    return current.isAfter(this.state.conceptsStartDate);
  }

  isValidStartDateForQuestions(current) {
    return current.isBefore(this.state.questionsEndDate);
  }

  isValidEndDateForQuestions(current) {
    return current.isAfter(this.state.questionsStartDate);
  }*/



  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props && this.props.summary && this.props.summary.encounters &&
      this.props.summary.encounters.length > 0 &&
      (!(prevProps.summary && prevProps.summary.timestamp) ||
        this.props.summary.timestamp > prevProps.summary.timestamp)) {

      var dateRange = dataTransService.getEncountersDatesRange(this.props.summary.encounters);

      //console.log("componentDidUpdate dates: ", dateRange.minDate, dateRange.maxDate, moment().subtract(7, "days"), moment()) 
      this.dateStartConcept = moment().subtract(7, "days");
      this.dateEndConcept = moment();
      this.dateStartQuestion = moment().subtract(7, "days");
      this.dateEndQuestion = moment();
      this.setState({
        conceptsStartDate: moment().subtract(7, "days"), //dateRange.minDate,
        conceptsEndDate: moment(), //dateRange.maxDate,
        questionsStartDate: moment().subtract(7, "days"), //dateRange.minDate,
        questionsEndDate: moment() //dateRange.maxDate
      }, () => {
        if (!this.state.minmaxDateFixed) {
          this.setState({
            conceptsMinDate: dateRange.minDate,
            conceptsMaxDate: dateRange.maxDate,
            questionsMinDate: dateRange.minDate,
            questionsMaxDate: dateRange.maxDate,
            minmaxDateFixed: true
          })
        }
      });

      //console.log("HERE4 encounters/participant: ", this.props.summary.encounters,this.props.summary.participant.alertConfig.conceptWeights);

      var conceptsArr = dataTransService.getAllConcepts(this.props.summary.encounters, this.props.summary.participant.alertConfig.conceptWeights);
      var questionsArr = dataTransService.getAllQuestions(this.props.summary.encounters, this.props.summary.participant.alertConfig.conceptWeights);


      if (questionsArr.length > 0) {
        this.setState({ questions: questionsArr }, () => {
          //if (dateRange.minDate !== this.state.questionsStartDate || dateRange.maxDate !== this.state.questionsEndDate) {
          this.plotQuestions(questionsArr, this.dateStartQuestion, this.dateEndQuestion); // this.plotQuestions(questionsArr, dateRange.minDate, dateRange.maxDate); JLIZASO 1 Week as Default.
          //console.log("PLOT ", dateRange.minDate, dateRange.maxDate);
          //}
        });
      }

      //console.log("HERE4: ", conceptsArr);
      if (conceptsArr.length > 0) {

        var seArray = conceptsArr.filter(concept => concept.name === "Side Effects");
        var deArray = conceptsArr.filter(concept => concept.name === "Delusions");
        var ahArray = conceptsArr.filter(concept => concept.name === "Auditory Hallucinations");
        var vhArray = conceptsArr.filter(concept => concept.name === "Visual Hallucinations");

        var restArray = conceptsArr.filter(concept => concept.name !== "Visual Hallucinations" &&
          concept.name !== "Side Effects" &&
          concept.name !== "Delusions" &&
          concept.name !== "Auditory Hallucinations"
        );

        conceptsArr = seArray.concat(deArray, ahArray, vhArray, restArray);

        this.setState({ concepts: conceptsArr }, () => {
          //console.log("HERE4 concepts: ", this.state.concepts);
          this.handleAllConcept();
          //if (dateRange.minDate !== this.state.conceptsStartDate ||
          //  dateRange.maxDate !== this.state.conceptsEndDate) {
          //  this.handleAllConcept();
          //  this.plotContepts(conceptsArr, dateRange.minDate, dateRange.maxDate);
          //}
        });
      }
    }
  }

  handleAllConcept = () => {
    let concepts = this.state.concepts
    //console.log("handleAllConcept: ", concepts);
    concepts.forEach(concept => {
      concept.selected = true;
    })
    this.setState({ concepts: concepts }, () => {
      //console.log("handleAllConcept: ", this.state.concepts);
      this.plotContepts(concepts);
    })
  }

  /*
  handleCheckConcept = (event) => {
    console.log("HERE6");
    let concepts = this.state.concepts
    concepts.forEach(concept => {
      if (concept.id === event.target.value)
        concept.selected = event.target.checked
    })
    this.setState({ concepts: concepts })
    this.plotContepts(concepts);
  }*/

  handleCheckQuestions = (event) => {
    let questions = this.state.questions
    questions.forEach(question => {
      if (question.uuid === event.target.value)
        question.selected = event.target.checked
    })
    this.setState({ questions: questions },
      this.plotQuestions(questions));
  }

  render() {
    if (this.state.questions !== undefined) {
      this.MinMaxAnswerValues = dataTransService.getMaxMinAnswerValues(this.state.questions);
      this.setChartOptions();
    }

    return (
      <>
        <PatientBanner id={this.props.match.params.pid} participant={this.props?.summary?.participant} />
        <PageWrapper title="Service User Summary" withPatientBanner>
          <>
            <Nav pills className="nav-pills-primary">
              <NavItem>
                <NavLink className={this.state.hTabs === "ht1" ? "active" : ""}
                  onClick={() => this.setState({ hTabs: "ht1" })}                      >
                  Encounters Graphs
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink className={this.state.hTabs === "ht2" ? "active" : ""}
                  onClick={() => this.setState({ hTabs: "ht2" })}                      >
                  Encounters Details
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink className={this.state.hTabs === "ht3" ? "active" : ""}
                  onClick={() => this.setState({ hTabs: "ht3" })} >
                  Symptom Graphs
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink className={this.state.hTabs === "ht4" ? "active" : ""}
                  onClick={() => this.setState({ hTabs: "ht4" })}>
                  Question Graphs
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink className={this.state.hTabs === "ht5" ? "active" : ""}
                  onClick={() => this.setState({ hTabs: "ht5" })}>
                  EWS List
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent
              activeTab={this.state.hTabs}
              className="tab-space">
              <TabPane tabId="ht1">
                <EncountersOverview />
              </TabPane>
              <TabPane tabId="ht2">
                <QuestionnaireList />
              </TabPane>
              <TabPane tabId="ht3">
                <SymptomsGraphsTab />
              </TabPane>
              <TabPane tabId="ht4">
                <Row>
                  <Col xs={12} md={12} className="mr-auto">
                    <Card className="card-chart">
                      <CardHeader>
                        <CardTitle tag="h5">Time Series Graph of Questions</CardTitle>
                        <DateFilter
                          setStartDate={this.handleStartDateQuestion}
                          setEndDate={this.handleEndDateQuestion}
                        />
                      </CardHeader>
                      <CardBody >
                        <Row>
                          <Col xs={12} md={8}>
                            <div className="chart-area">
                              <Line data={this.state.questionsChartData.data} options={this.chartOptions} />
                            </div>
                          </Col>
                          <Col xs={12} md={4}>
                            <Button className="btn-fill" color="info"
                              onClick={this.changeQuestionsSelection.bind(this)}>
                              {this.state.selectAllQ}
                            </Button>
                            <QuestionsList>
                              {
                                this.state.questions.map((el, i) => {
                                  return (<FormGroup check key={`q${i}`}>
                                    <Label check   >
                                      <Input type="checkbox" checked={el.selected} onChange={this.handleCheckQuestions.bind(this)} value={el.uuid} />
                                      <span className="form-check-sign" />
                                      <div style={{ backgroundColor: el.color, width: 10, height: 10, display: 'inline-block' }}></div>  {el.text}
                                    </Label>
                                  </FormGroup>);
                                })
                              }
                            </QuestionsList>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="ht5">
                <AlertsList participantId={this.props.match.params.pid} />
              </TabPane>
            </TabContent>
          </>
        </PageWrapper>
      </>
    );
  }
}


function mapState(state) {
  const { summary, questions, participantsLoading } = state.participants;

  return { summary, questions, participantsLoading };
}

const actionCreators = {
  getSummary: participantsActions.getSummary,
  getQuestions: questionsActions.getAll
};

const connectedParticipantSummary = connect(mapState, actionCreators)(ParticipantSummary);
export { connectedParticipantSummary as ParticipantSummary };