
/*eslint-disable*/
import React from "react";
import { Container } from "reactstrap";
import styled from 'styled-components';
// used for making the prop types of this component
import PropTypes from "prop-types";

const VersionText = styled.span`
  float: right;

  @media (max-width: 576px) {
    float: none;
    display: block;
    text-align: center;
  }
`;

/* {1900 + new Date().getYear()} instead of 2021 */
class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      version: "",
    };
  }

  componentDidMount() {
    fetch("/version.json")
        .then((response) => response.json())
        .then((data) => {
          this.setState({ version: data.version });
        })
        .catch(() => {
          this.setState({ version: "Unknown Portal Version." });
        });
  }

  render() {
    return (
      <footer
        className={"footer" + (this.props.default ? " footer-default" : "")}
      >
        <Container fluid={this.props.fluid}>
          <div className="copyright">
            &copy; {1900 + new Date().getYear()}. Built by {" "}
            <a
              href="http://www.careloophealth.com//"
              target="_blank"
            >
              Careloop Health
            </a>
            .
            <br/>
            <VersionText>Version: {this.state.version}</VersionText>
          </div>
        </Container>
      </footer>
    );
  }
}

Footer.defaultProps = {
  default: false,
  fluid: false
};

Footer.propTypes = {
  default: PropTypes.bool,
  fluid: PropTypes.bool
};

export default Footer;
